import React, {useState} from 'react'
import { Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';

/**
 * SearchTextField component.
 * @memberof module:Taskpane/Components
 * @param {Object} props - The props object.
 * @param {string} props.label - The label for the TextField.
 * @param {Object} props.inputProps - The input props for the TextField.
 * @param {Object} props.InputLabelProps - The input label props for the TextField.
 * @param {string} props.value - The value of the TextField.
 * @param {Function} props.onChange - The onChange event handler for the TextField.
 * @param {Function} props.onFocus - The onFocus event handler for the TextField.
 * @param {ReactNode} props.icon - The icon to be displayed in the Button.
 * @param {ReactNode} props.children - The children components to be rendered when search is toggled.
 * 
 * @returns {JSX.Element} The SearchTextField component.
 * 
 * @example
 * return (
 *      <SearchTextField label="Search" inputProps={{}} InputLabelProps={{}} value={value} onChange={onChange} onFocus={onFocus} icon={<SearchIcon />}>
 *         <div>Children</div>
 *      </SearchTextField>
 * )
 */
function SearchTextField(props) {
    const [searchToggled, setSearchToggled] = useState(false)

    const handleSearchToggled = () => {
        setSearchToggled(!searchToggled)
    }

    return (
        <div style={{width: "100%", display: "flex", flexDirection: "column"}}>
        
        <div style={{width: "100%", display: "flex", flexDirection: "row"}}>
            <TextField  
                label={props.label}
                size="small" margin="dense"
                fullWidth={true}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    border: "none",
                    marginRight: "5px"
                }}
                inputProps={props.inputProps}
                InputLabelProps={props.InputLabelProps}
                value={props.value}
                onChange={(event) => {props.onChange(event)}}
                onFocus={(event) => {props.onFocus(event)}}
            />

            <Button 
                type="button" 
                sx={{
                    margin: "7px 0",
                    minWidth: "0px",
                    width: "30px",
                    backgroundColor: "blueviolet",
                    '&:hover': {
                        backgroundColor: 'rgba(137, 43, 226, 0.822)',
                    },
                }} 
                variant="contained" 
                aria-label="search" 
                onClick={handleSearchToggled}
            >   
                {
                    searchToggled ? <CloseIcon /> :  (props.icon ? props.icon : <SearchIcon />)
                }
            
            </Button>
        </div>

        {
            searchToggled && React.Children.map(props.children, item => {
                return React.cloneElement(item, {
                    onClose: handleSearchToggled
                });
            })
        }


        </div>
    );
}


export default SearchTextField;