/**
 * @module Taskpane/Components
 */

import React, { useState, useEffect} from 'react'
import Progress from "./Progress/Progress";
import Form from "./Form/Form.js"
import RawQueryForm from "./RawQueryForm/RawQueryForm.js";
import {addFormulaToCell} from "../utils/ExcelUtils.js";
import { getTablesAndCatalogs, searchTagNames } from "../../functions/functionUtils.js";
import Alert from '@mui/material/Alert';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import SettingsDialog from "./SettingsDialog/SettingsDialog.js";
import ViewListIcon from '@mui/icons-material/ViewList';
import Dashboard from "./Dashboard/Dashboard"
import WelcomeScreen from "./WelcomeScreen/WelcomeScreen.js"
import UpdateScreen from "./UpdateScreen/UpdateScreen.js"
import useLocalStorage from '../hooks/useLocalStorage';
import useSettings from '../hooks/useSettings';
import "../taskpane.css"
import {APP_VERSION_NUMBER} from "../../../constants"

/* global console, Excel, require */

/**
 * Represents the main component of the application.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.isOfficeInitialized - Indicates whether the Office add-in is initialized.
 * @param {string} props.title - The title of the application.
 * @returns {JSX.Element} The rendered component.
 * 
 * @example
 * return <App isOfficeInitialized={isOfficeInitialized} title={title}/>
 */
function App(props) {

  // General state 
  const [error, setError] = useState({occured: false, message: ""})
  const [switchAlignment, setSwitchAlignment] = useState("Guided")
  const [formData, setFormData] = useState({})
  const [sqlFormData, setSQLFormData] = useState({})

   // Structure of dashboard: {formulas: [{address: "A1", content: "=RTDIP.timeweightedavg()"}, parsedParameters: {"param": ...}], sheetName: "Sheet1"}
   const [dashboardFormulaData, setDashboardFormulaData] = useState({formulas: [], sheetName: ""})

  // Local storage hooks
  const [seenWelcomeScreen, setSeenWelcomeScreen] = useLocalStorage('seenWelcomeScreen', false);
  const [storedAppVersionNumber, setStoredAppVersionNumber] = useLocalStorage('APP_VERSION_NUMBER', "");
 
  // Settings hook - utilizes local storage
  const [settings, setSettings] = useSettings();

  // Once welcome screen is seen, set the settings and app version number
  useEffect(() => {
    if(seenWelcomeScreen){
      setSettings({apiUrl: settings["apiUrl"], ...settings})
    }
  }
  , [seenWelcomeScreen])

  const handleSwitchChange = (event, newAlignment) => {
    setError({occured: false, message: ""})
    setSwitchAlignment(newAlignment);
  };

  const handleSettingsChange = (newSettings) => {
    setSettings(newSettings)
  }

  const handleClick = async (parameters, paramaterNames) => {
    // Handles when form(s) are submitted

    // Reset error
    setError({occured: false, message: ""})

    // Run the addFormulaToCell function - utilises Excel API
    await addFormulaToCell({
      params: parameters, 
      paramNames: paramaterNames,
      settings: settings,
    })
  };

  const handleTableCatalogQuery = async (parameters) => {
    // API query to get tables and catalogs
    return await getTablesAndCatalogs(settings["apiUrl"], parameters)
  }

  const handleTagSearchQuery = async (parameters) => {
    // API query to search for tag names
    return await searchTagNames(settings["apiUrl"], parameters)
  }

  const handleFormulaEdit = (address, params) => {
    // Handles when a formula is edited in the dashboard
    if(params["query_type"] == "sql"){
      setSwitchAlignment("Raw-SQL")
      setSQLFormData({
        outputCell: address,
        ...params
      })
    } else {
      setSwitchAlignment("Guided")
      setFormData({
        outputCell: address,
        ...params
      })
    }
  }

  const handleError = (errorMessage) => {
    setError({occured: true, message: errorMessage})
  }

  const handleWelcomeComplete = () => {
    setSeenWelcomeScreen(true)
    setStoredAppVersionNumber(APP_VERSION_NUMBER)
  }

  const handleUpdateClose = () => {
    // Update app version number once update screen is closed
    setStoredAppVersionNumber(APP_VERSION_NUMBER)
  }

  return (

    !props.isOfficeInitialized ? 
        <Progress
          title={props.title}
          logo={require("./../../../assets/rtdip-icon-300.png")}
          message="Please sideload your addin to see app body."
        />
      :      
        <div className="taskpane">

          {!seenWelcomeScreen && <WelcomeScreen handleWelcomeComplete={handleWelcomeComplete}/>}

          {(seenWelcomeScreen && storedAppVersionNumber !== APP_VERSION_NUMBER)
            && 
            <UpdateScreen handleUpdateClose={handleUpdateClose}/>
          }

          <div className="image-container">
            <img src="./../../../assets/rtdip-horizontal-color.png" id="rtdip-logo" alt="rtdip-logo"></img>
          </div>

          <ToggleButtonGroup
            color="primary"
            value={switchAlignment}
            exclusive
            onChange={handleSwitchChange}
            size="small"
            sx={{ 
              marginBottom: '10px',
              display: "flex",
              justifyContent: "center"
              }}
          >
            <ToggleButton color="info" value="Guided">Auto Query</ToggleButton>
            <ToggleButton value="Raw-SQL">Raw SQL</ToggleButton>
            <ToggleButton value="Dashboard"><ViewListIcon/></ToggleButton>
          </ToggleButtonGroup>

          {switchAlignment == "Guided" && <Form handleClick={handleClick} handleTableCatalogQuery={handleTableCatalogQuery} handleTagSearchQuery={handleTagSearchQuery} formData={formData} handleError={handleError}/>}
          {switchAlignment == "Raw-SQL" && <RawQueryForm handleClick={(handleClick)} sqlFormData={sqlFormData} handleError={handleError}/>}
          {switchAlignment == "Dashboard" && <Dashboard formulaData={dashboardFormulaData} setFormulaData={setDashboardFormulaData} handleEdit={handleFormulaEdit} handleError={handleError}/>}

          <SettingsDialog 
            handleSettingsChange={handleSettingsChange}
            settings={settings}
          />

          {error.occured && 
            <Alert sx={{ marginTop: '10px' }} severity="error">
              {error.message}
            </Alert>
          }

        </div>
      )
}


export default App;